import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <div className="container-fluid footer">
      <h1 className='page-not-found text-center'>This page doesn't seem to exist.</h1>
     <div className="page-not-found-button text-center">
                           <Link to="/interior" target="_blank" rel="noopener noreferrer"> <button className="aboutus-button-1">View Products</button> </Link>
                           <Link to="/gallery" target="_blank" rel="noopener noreferrer">    <button className="aboutus-button-2">View Designs</button> </Link>
                         </div>
      </div>
    </div>
  );
};

export default NotFound;
