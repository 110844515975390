// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/home/cookscape-final.png';
const Navbar = () => {
  return (
    <nav class="navbar navbar-expand-lg">
      <div class="container"> 
      <Link to="/">
      <img src={logo} alt="Cookscape-logo" class="logo"/>
        </Link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars line" aria-hidden="true"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item"> 
          <Link to="/" className='nav-link'>Home</Link>
          </li>
          
          <li class="nav-item dropdown">
          <Link to="/" className='nav-link dropdown-toggle' id='navbarDropdown' role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</Link> 
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link to="/wardrobe" className='dropdown-item'>Wardrobe</Link>
          <Link to="/kitchen" className='dropdown-item'>Modular Kitchen</Link>
          <Link to="/tvunit" className='dropdown-item'>TV Units</Link>
          <Link to="/kids-furniture" className='dropdown-item'>Kids Furniture</Link>
          <Link to="/poojaunit" className='dropdown-item'>Pooja Units</Link>
          <Link to="/renovation" className='dropdown-item'>Renovation</Link>
          </ul>
          </li>
          <li class="nav-item"> 
          <Link to="/customer-speaks" className='nav-link'>Customer Speaks</Link>
          </li>
          <li class="nav-item dropdown">
          <Link to="/" className='nav-link dropdown-toggle' id='navbarDropdown' role="button" data-bs-toggle="dropdown" aria-expanded="false">More</Link> 
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <Link to="/gallery" className='dropdown-item'>Gallery</Link>
          <Link to="/blog" className='dropdown-item'>Blogs</Link>
          <Link to="/group-websites" className='dropdown-item'>Group Websites</Link>
          <Link to="/happenings" className='dropdown-item'>Happenings</Link>
          <Link to="/press-release" className='dropdown-item'>Press Release</Link>
          <Link to="/contactus" className='dropdown-item'>Outlet</Link>
          <Link to="/privacy-policy" className='dropdown-item'>Privacy Policy</Link>

          </ul>
          </li>
          <li class="nav-item"> 
          <Link to="/interior" className='nav-link'>Manufacturing Unit</Link>
          </li>
          </ul>
            <button className="enquiry" data-bs-toggle="modal" data-bs-target="#verticalModal">Get Free Design Catalogue</button>
          </div>
      </div>
    </nav>
  );
};
export default Navbar;
