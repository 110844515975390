import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog23 from '../images/blogs/blog-images/cookscape-blog-23 .webp';
import blog23a from '../images/blogs/blog-images/cookscape-blog-23a .webp';
import arviswa from '../images/blogs/architects/Cookscape-Architect-Viswanath .webp';
const Blog3c = () => {
return (
<>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/easy-step-to-plan-your-kitchen-very-modular"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
</div>
<div className='container-fluid aboutus'>
      <div className='container p-5'>
      <h1 className='blog-page-title pt-3'>Easy step to plan your kitchen very modular!</h1>
      <img src={blog23} alt="cookscape-modular-kitchen" class="img-fluid pt-3"/>
      <h2 className='blog-page-title-2 pt-3'>MODULAR KITCHEN:</h2>
      <p className='blog-page-para'>Generally we want kitchen very modular for our convenience and aesthetic. For example; in a normal kitchen we keep things where the space is available but in a modular kitchen we can allot a space as per our convenience. Like convenience we have to give priority for look and style. While planning for a modular kitchen we have to consider things like material, quality and finish. Also there is a rule while designing for modular kitchen “Golden triangle”. The kitchen work triangle or a golden triangle determines the perfect location of Hobbs, refrigerator and sink.</p>
      <h2 className='blog-page-title-2 pt-3'>MATERIAL:</h2>
      <p className='blog-page-para'>We have different kinds of materials to make our kitchen very modular. Materials like wood, steel, glass, PVC etc., can be used. Also I will share you the positives and negatives of these materials.</p>
      <h2 className='blog-page-title-2 pt-3'>Wood:</h2>
      <p className='blog-page-para'>The quality and durability of wood cannot be replaced by other materials. Also it act as a noise absorber and insulator. The types of wood used for modular kitchen are teakwood, rosewood, rubber wood, Oakwood, cidarwood, etc. These woods can be used after seasoning will last long and that type of finish is called factory made. Also this will be done without seasoning by local carpenters and that may not last long compared to factory made. Plywood’s, Mdf is also a type of wood with a less lifespan and it will be suitable for a peoples who wants to do their kitchen in a small budget. A compressed wood powder is called MDF(medium density fibre). </p>
      <h2 className='blog-page-title-2 pt-3'>Steel:</h2>
      <p className='blog-page-para'>Steel can afford more heat but compare to other material steel has more flaws. Rusting, corrosion, paint will peeled off, are all the negatives of steel. It is mainly used for commercial kitchen only.</p>
      <h2 className='blog-page-title-2 pt-3'>Glass:</h2>
      <p className='blog-page-para'>It is used in kitchen cabinets like shutters, crockery shelves, crockery doors, etc. Also no carvings can be done in glass. Clear glass can be used for transparent look and can arrange things for our display. Frosted glass can give a sense of privacy and looks stylish. </p>
      <h2 className='blog-page-title-2 pt-3'>Pvc:</h2>
      <p className='blog-page-para'>Poly vinyl chloride is also called PVC. Its flexible nature suits for industrial, construction and kitchen cabinets. Also it is used for cabinet legs in interiors for its durability, moisture and water resistance. Also pvc cannot sustain heat for a long time and like plywood people who are budget constraints, Pvc is suitable.</p>
      <h2 className='blog-page-title-2 pt-3'>QUALITY:</h2>
      <p className='blog-page-para'>We generally required quality in any product we are about to buy. The cost we spend will decides the quality of the material and service. The more we spend the better we get. In terms of quality in modular kitchen, we have to be very concern about the material, service and finish. These things we can expect only with the authorised and branded firms not with others. Normal carpenter work will spoil our privacy and space in our home. A kitchen is one of the unavoidable factor in party, get to gathers, festivals. Our eating and cooking experience depends on the kitchen design. Other than comfort and aesthetics, there are many significant purposes involves in kitchen design. An awesome kitchen design should consider the space for work and lightings to be fit in a proper place. </p>
      <h2 className='blog-page-title-2 pt-3'>FINISHING:</h2>
      <img src={blog23a} alt="cookscape-modular-kitchen" class="img-fluid pt-3"/>
      <p className='blog-page-para'>The glazing is the technique to highlight the wood work finish and it also used to give age to the finish. There are so many types of oil and oil mixed chemicals are available to get a glazing or shining to our wood works. Also shellac type of liquids is not suitable for table top finish because it has an ability to catch fire. Buying a matching carpets, matching appliances, flower pots, etc. will give an attraction for our kitchen. By considering all this things we can easily plan our modular kitchen.</p>
      <img src={arviswa} alt="Architect-Viswanath" class="blog-page-author-img pt-3"/>
      <h1 className='blog-title pt-3'> Architect Viswanath</h1>
      </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
</>
              );
            };
export default Blog3c;
